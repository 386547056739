import "./teaching.css";
import Grid from "@mui/material/Grid";
import TeachingCard from "../../components/TeachingCard/TeachingCard";

const Teaching = () => {
  return (
    <div className="teaching">
      <h1 className="teaching__header">
        (2022-2025) Méthodes Numériques, L2 MIASHS
      </h1>
      <div className="teaching__container">
        <Grid
          container
          columns={{ xs: 4, sm: 4, md: 8, lg: 12 }}
          maxWidth={{ md: "900px", lg: "1300px" }}
          margin={"auto"}
          style={{
            maxHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <Grid item xs={4} padding={"10px"}>
            <TeachingCard
              title={"TD 1"}
              desc={"Prise en main de Python."}
              pathFolder={"tp1/"}
              hrefDownload={"tp1_python.ipynb"}
              hrefInfo={"intro_python.pdf"}
              hrefPython={"tp1_python_corr.ipynb"}
              hrefR={"tp1_MN_L2MIASHS_corr.rmd"}
            />
          </Grid>
          <Grid item xs={4} padding={"10px"}>
            <TeachingCard
              title={"TD 2"}
              desc={"Dichotomie."}
              pathFolder={"tp2/"}
              hrefDownload={"tp2_MN_L2MIASHS.pdf"}
              hrefPython={"tp2_python_corr.ipynb"}
              hrefR={"tp2_MN_L2MIASHS_corr.rmd"}
              hrefDoneAll={"tp2_notes.pdf"}
            // blurred={true}
            />
          </Grid>
          <Grid item xs={4} padding={"10px"}>
            <TeachingCard
              title={"TD 3"}
              desc={"Méthodes de point fixe."}
              pathFolder={"tp3/"}
              hrefDownload={"tp3_MN_L2MIASHS.pdf"}
              hrefR={"tp3_MN_L2MIASHS.rmd"}
              hrefPython={"tp3_python_corr.ipynb"}
              hrefDoneAll={"tp3_notes.pdf"}
            // blurred={true}
            />
          </Grid>
          <Grid item xs={4} padding={"10px"}>
            <TeachingCard
              title={"TD 4"}
              desc={"Factorisation LU et Cholesky."}
              pathFolder={"tp4/"}
              hrefDownload={"tp4_MN_L2MIASHS.pdf"}
              hrefPython={"tp4_python.ipynb"}
              hrefDoneAll={"tp4_corr.pdf"}
            // blurred={true}
            />
          </Grid>
          <Grid item xs={4} padding={"10px"}>
            <TeachingCard
              title={"TD 0"}
              desc={"Rappels d'algèbre linéaire."}
              pathFolder={"tp0/"}
              hrefDownload={"tp0_MN_L2MIASHS.pdf"}
            // hrefDoneAll={"tp0_corr.pdf"}
            // blurred={true}
            />
          </Grid>
          <Grid item xs={4} padding={"10px"}>
            <TeachingCard
              title={"TD 5"}
              desc={"Méthodes itératives et rappels d'algèbre linéaire."}
              pathFolder={"tp5/"}
              // hrefDownload={"tp5_MN_L2MIASHS.pdf"}
              // hrefDoneAll={"tp5_corr.pdf"}
              // hrefInfo={"tp5_whiteboard.pdf"}
              blurred={true}
            />
          </Grid>
          <Grid item xs={4} padding={"10px"}>
            <TeachingCard
              title={"DM"}
              desc={"Devoir maison"}
              pathFolder={"dm/"}
              // hrefDownload={"dm.pdf"}
              // hrefDoneAll={"correction_dm.pdf"}
              blurred={true}
            />
          </Grid>
          <Grid item xs={4} padding={"10px"}>
            <TeachingCard
              title={"CC2"}
              desc={"Rappels de cours et correction CC2"}
              pathFolder={"cc2/"}
              hrefDownload={"cc2_sujet.pdf"}
              hrefDoneAll={"cc2_correction.pdf"}
            // blurred={true}
            />
          </Grid>
          <Grid item xs={4} padding={"10px"}>
            <TeachingCard
              title={"TD 6"}
              desc={"TD 6"}
              pathFolder={"tp6/"}
              // hrefDownload={"tp6_MN_L2MIASHS.pdf"}
              // hrefDoneAll={"whiteboard_tp6.pdf"}
              // hrefDoneAll={"Partiel_MN_2022_correction.pdf"}
              blurred={true}
            />
          </Grid>
          <Grid item xs={4} padding={"10px"} margin={"auto auto 300px auto"}>
            <TeachingCard
              title={"Préparation partiel"}
              desc={"Correction du partiel de 2022"}
              pathFolder={"partiel/"}
              // hrefDownload={"Partiel_MN_2022.pdf"}
              // hrefDoneAll={"Partiel_MN_2022_correction.pdf"}
              blurred={true}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Teaching;
